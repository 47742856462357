<template>
    <div class="login">
        <div class="con">
            <el-form ref="form" :model="form" label-position="top">
                <div class="login-title">会员注册</div>
                <el-form-item label="">
                    <el-input v-model="form.name" prefix-icon="el-icon-user" placeholder="请输入用户手机号"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-input v-model="form.password" prefix-icon="el-icon-view" placeholder="请输入用户密码" type="password"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-input v-model="form.repassword" prefix-icon="el-icon-view" placeholder="请确认密码" type="password"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-input v-model="form.code" prefix-icon="el-icon-suitcase" placeholder="请输入验证码">
                        <template slot="append">获取验证码</template>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="register" class="login-btn">注册</el-button>
                </el-form-item>
                <div class="login-register">
                    <router-link to="/login">已有账号，直接登录<i class="el-icon-arrow-right"></i></router-link>
                </div>
            </el-form>
        </div>

    </div>
</template>

<script>
export default {
    name: "Register",
    data() {
        return {
            form: {
                name: '',
                password: '',
                repassword: '',
                code: '',
            }
        }
    },
    methods: {
        register() {}
    }
}
</script>

<style lang="scss" scoped>
.login {
    width: 100%;
    height: 600px;
    background: url("~@/assets/images/loginbg.jpg") no-repeat top left;
    background-size: cover;
    overflow: hidden;
    .el-form {
        margin-top: 20px;
        width: 400px;
        height: 480px;
        background-color: #fff;
        padding: 20px 26px;
        box-sizing: border-box;
        margin-left: 500px;
        position: relative;
        .login-title {
            line-height: 55px;
            font-size: 22px;
            font-weight: bold;
            text-align: center;
            color: #1ED0AA;
            border-bottom: 1px solid #ccc;
            margin-bottom: 22px;
        }
        .login-forget {
            margin-bottom: 22px;
            text-align: right;
            font-size: 12px;
            a:hover {
                color: #1ED0AA;
            }
        }
        .login-btn {
            width: 100%;
        }
        .login-register {
            background: #F5F4F4;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 70px;
            line-height: 70px;
            text-align: right;
            padding-right: 26px;
            a {
                color: #1ED0AA;
            }
        }
    }
}
</style>
